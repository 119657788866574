import React from 'react';

import Home from '../site/home/home';
import NavBar from '../_layouts/navBar';
import ContactUs from '../site/contact_us/contact_us';
import FormCards from '../site/loans_forms/form_Cards';
import Servicios from '../site/servicios/servicios';
import Calculadora from '../site/calculator/calculator_Section';
import SideBar from "../_layouts/burger";


class BodyContent extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<div id="page-wrap">
				<SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
				<div id="App">
					<NavBar all={true} />
				</div>
				<div id="home">
					<Home />
				</div>
				<div id="servicios">
					<Servicios />
				</div>
				<div id="Calculadora">
					<Calculadora />
				</div>
				 <div id="prestamos">
					<FormCards />
				</div>
				<div id="contactanos">
					<ContactUs />
				</div>
			</div>
		);
	}
}

export default BodyContent;
