import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
import NavBar from '../../../_layouts/navBar';
import SuccessMessage from '../../views/success_message';

class Vehicle_Form extends React.Component {
  constructor(props) {
    super(props);
    this.url = "http://inversionesnorvi-backend-inversionesnorvi.1d35.starter-us-east-1.openshiftapps.com/";
    this.state = {
      redirect: false,
      disable: false,
      fields: {
        namep: '',
        lastnamep: '',
        genderp:'Hombre',
        birthdatep:'',
        addressp: '',
        sectorp: '',
        cityp: '',
        emailp:'',
        documentp:'',
        phonep:'',
        movilp:'',
        contactPref: {
          phoneCall: false,
          email: false,
          whatsApp: false
        },
        jobInfo: {
          jobname:'',
          rol: '',
          department: '',
          salary: '',
          jobaddress: '',
          jobmail: '',
          supName: '',
          supphone: '',
          others: '',
          othersdetail: ''
        },
        personalRef1: {
          namep: '',
          lastnamep: '',
          genderp:'Hombre',
          relation:'',
          addressp: '',
          sectorp: '',
          cityp: '',
          emailp:'',
          phonep:'',
          movilp:''
        },
        personalRef2: {
          namep: '',
          lastnamep: '',
          genderp:'Hombre',
          relation:'',
          addressp: '',
          sectorp: '',
          cityp: '',
          emailp:'',
          phonep:'',
          movilp:''
        },
        personalRef3: {
          namep: '',
          lastnamep: '',
          genderp:'Hombre',
          relation:'',
          addressp: '',
          sectorp: '',
          cityp: '',
          emailp:'',
          phonep:'',
          movilp:''
        },
        bankRef: {
          bank: '',
          office: '',
          phone: '',
          bankAccount: '',
          bankPerson: '',
          ext: ''
        },
        bankRef2: {
          bank: '',
          office: '',
          phone: '',
          bankAccount: '',
          bankPerson: '',
          ext: ''
        },
        guarantInfo: {
          namep: '',
          lastnamep: '',
          genderp:'Hombre',
          birthdatep:'',
          addressp: '',
          sectorp: '',
          cityp: '',
          emailp:'',
          documentp:'',
          phonep:'',
          movilp:''
        },
        guarantJobInfo: {
          jobname:'',
          rol: '',
          department: '',
          salary: '',
          jobaddress: '',
          jobmail: '',
          supName: '',
          supphone: '',
          others: '',
          othersdetail: ''
        },
        loanDetails: {
          amount: '',
          months: ''
          // loanPurpose: ''
        },
        carDetails: {
          brand: '',
          model: '',
          year: '',
          valueAmount: 0
        }
      },
      errors: {
        jobInfo:{},
        personalRef1:{},
        personalRef2:{},
        personalRef3:{},
        bankRef: {},
        guarantInfo: {},
        guarantJobInfo: {},
        loanDetails: {},
        carDetails:{}
      }
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {
      jobInfo:{},
      personalRef1:{},
      personalRef2:{},
      personalRef3:{},
      bankRef: {},
      guarantInfo: {},
      guarantJobInfo: {},
      loanDetails: {},
      carDetails:{}
    };
    let formIsValid = true;
    let emptytxt = "No puede estar vacio"
    
    //Detalles personales
    if(!fields["namep"]){formIsValid = false; errors["namep"] = emptytxt;}
    if(!fields["lastnamep"]){formIsValid = false; errors["lastnamep"] = emptytxt;}
    if(!fields["birthdatep"]){formIsValid = false; errors["birthdatep"] = emptytxt;}
    if(!fields["addressp"]){formIsValid = false; errors["addressp"] = emptytxt;}
    if(!fields["sectorp"]){formIsValid = false; errors["sectorp"] = emptytxt;}
    if(!fields["cityp"]){formIsValid = false; errors["cityp"] = emptytxt;}
    if(!fields["emailp"]){formIsValid = false; errors["emailp"] = emptytxt;}
    if(!fields["documentp"]){formIsValid = false; errors["documentp"] = emptytxt;}
    if(!fields["phonep"]){formIsValid = false; errors["phonep"] = emptytxt;}
    if(!fields["movilp"]){formIsValid = false; errors["movilp"] = emptytxt;}
    //Informacion laboral
    if(!fields.jobInfo["jobname"]){formIsValid = false; errors.jobInfo["jobname"] = emptytxt;}
    if(!fields.jobInfo["rol"]){formIsValid = false; errors["jobInfo"]["rol"] = emptytxt;}
    if(!fields.jobInfo["department"]){formIsValid = false; errors["jobInfo"]["department"] = emptytxt;}
    if(!fields.jobInfo["salary"]){formIsValid = false; errors["jobInfo"]["salary"] = emptytxt;}
    if(!fields.jobInfo["jobaddress"]){formIsValid = false; errors["jobInfo"]["jobaddress"] = emptytxt;}
    if(!fields.jobInfo["jobmail"]){formIsValid = false; errors["jobInfo"]["jobmail"] = emptytxt;}
    if(!fields.jobInfo["supName"]){formIsValid = false; errors["jobInfo"]["supName"] = emptytxt;}
    if(!fields.jobInfo["supphone"]){formIsValid = false; errors["jobInfo"]["supphone"] = emptytxt;}
    if(!fields.jobInfo["others"]){formIsValid = false; errors["jobInfo"]["others"] = emptytxt;}
    if(!fields.jobInfo["othersdetail"]){formIsValid = false; errors["jobInfo"]["othersdetail"] = emptytxt;}
    //Referencia Personal 1
    if(!fields.personalRef1["namep"]){formIsValid = false; errors.personalRef1["namep"] = emptytxt;}
    if(!fields.personalRef1["lastnamep"]){formIsValid = false; errors.personalRef1["lastnamep"] = emptytxt;}
    if(!fields.personalRef1["relation"]){formIsValid = false; errors.personalRef1["birthdatep"] = emptytxt;}
    if(!fields.personalRef1["addressp"]){formIsValid = false; errors.personalRef1["addressp"] = emptytxt;}
    if(!fields.personalRef1["sectorp"]){formIsValid = false; errors.personalRef1["sectorp"] = emptytxt;}
    if(!fields.personalRef1["cityp"]){formIsValid = false; errors.personalRef1["cityp"] = emptytxt;}
    if(!fields.personalRef1["emailp"]){formIsValid = false; errors.personalRef1["emailp"] = emptytxt;}
    if(!fields.personalRef1["phonep"]){formIsValid = false; errors.personalRef1["phonep"] = emptytxt;}
    if(!fields.personalRef1["movilp"]){formIsValid = false; errors.personalRef1["movilp"] = emptytxt;}
    //Referencia Personal 2
    if(!fields.personalRef2["namep"]){formIsValid = false; errors.personalRef2["namep"] = emptytxt;}
    if(!fields.personalRef2["lastnamep"]){formIsValid = false; errors.personalRef2["lastnamep"] = emptytxt;}
    if(!fields.personalRef2["relation"]){formIsValid = false; errors.personalRef2["relation"] = emptytxt;}
    if(!fields.personalRef2["addressp"]){formIsValid = false; errors.personalRef2["addressp"] = emptytxt;}
    if(!fields.personalRef2["sectorp"]){formIsValid = false; errors.personalRef2["sectorp"] = emptytxt;}
    if(!fields.personalRef2["cityp"]){formIsValid = false; errors.personalRef2["cityp"] = emptytxt;}
    if(!fields.personalRef2["emailp"]){formIsValid = false; errors.personalRef2["emailp"] = emptytxt;}
    if(!fields.personalRef2["phonep"]){formIsValid = false; errors.personalRef2["phonep"] = emptytxt;}
    if(!fields.personalRef2["movilp"]){formIsValid = false; errors.personalRef2["movilp"] = emptytxt;}
    //Referencia Personal 3
    if(!fields.personalRef3["namep"]){formIsValid = false; errors.personalRef3["namep"] = emptytxt;}
    if(!fields.personalRef3["lastnamep"]){formIsValid = false; errors.personalRef3["lastnamep"] = emptytxt;}
    if(!fields.personalRef3["relation"]){formIsValid = false; errors.personalRef3["relation"] = emptytxt;}
    if(!fields.personalRef3["addressp"]){formIsValid = false; errors.personalRef3["addressp"] = emptytxt;}
    if(!fields.personalRef3["sectorp"]){formIsValid = false; errors.personalRef3["sectorp"] = emptytxt;}
    if(!fields.personalRef3["cityp"]){formIsValid = false; errors.personalRef3["cityp"] = emptytxt;}
    if(!fields.personalRef3["emailp"]){formIsValid = false; errors.personalRef3["emailp"] = emptytxt;}
    if(!fields.personalRef3["phonep"]){formIsValid = false; errors.personalRef3["phonep"] = emptytxt;}
    if(!fields.personalRef3["movilp"]){formIsValid = false; errors.personalRef3["movilp"] = emptytxt;}
    //Referencias Bancarias
    if(!fields.bankRef["bank"]){formIsValid = false; errors.bankRef["bank"] = emptytxt;}
    if(!fields.bankRef["office"]){formIsValid = false; errors.bankRef["office"] = emptytxt;}
    if(!fields.bankRef["phone"]){formIsValid = false; errors.bankRef["phone"] = emptytxt;}
    if(!fields.bankRef["bankAccount"]){formIsValid = false; errors.bankRef["bankAccount"] = emptytxt;}
    // if(!fields.bankRef["bankPerson"]){formIsValid = false; errors.bankRef["bankPerson"] = emptytxt;}
    if(!fields.bankRef["ext"]){formIsValid = false; errors.bankRef["ext"] = emptytxt;}
    //Datos del Garante
    if(!fields.guarantInfo["namep"]){formIsValid = false; errors.guarantInfo["namep"] = emptytxt;}
    if(!fields.guarantInfo["lastnamep"]){formIsValid = false; errors.guarantInfo["lastnamep"] = emptytxt;}
    if(!fields.guarantInfo["birthdatep"]){formIsValid = false; errors.guarantInfo["birthdatep"] = emptytxt;}
    if(!fields.guarantInfo["addressp"]){formIsValid = false; errors.guarantInfo["addressp"] = emptytxt;}
    if(!fields.guarantInfo["sectorp"]){formIsValid = false; errors.guarantInfo["sectorp"] = emptytxt;}
    if(!fields.guarantInfo["cityp"]){formIsValid = false; errors.guarantInfo["cityp"] = emptytxt;}
    if(!fields.guarantInfo["emailp"]){formIsValid = false; errors.guarantInfo["emailp"] = emptytxt;}
    if(!fields.guarantInfo["documentp"]){formIsValid = false; errors.guarantInfo["documentp"] = emptytxt;}
    if(!fields.guarantInfo["phonep"]){formIsValid = false; errors.guarantInfo["phonep"] = emptytxt;}
    if(!fields.guarantInfo["movilp"]){formIsValid = false; errors.guarantInfo["movilp"] = emptytxt;}
    //Informacion laboral garante
    if(!fields.guarantJobInfo["jobname"]){formIsValid = false; errors.guarantJobInfo["jobname"] = emptytxt;}
    if(!fields.guarantJobInfo["rol"]){formIsValid = false; errors["guarantJobInfo"]["rol"] = emptytxt;}
    if(!fields.guarantJobInfo["department"]){formIsValid = false; errors["guarantJobInfo"]["department"] = emptytxt;}
    if(!fields.guarantJobInfo["salary"]){formIsValid = false; errors["guarantJobInfo"]["salary"] = emptytxt;}
    if(!fields.guarantJobInfo["jobaddress"]){formIsValid = false; errors["guarantJobInfo"]["jobaddress"] = emptytxt;}
    if(!fields.guarantJobInfo["jobmail"]){formIsValid = false; errors["guarantJobInfo"]["jobmail"] = emptytxt;}
    if(!fields.guarantJobInfo["supName"]){formIsValid = false; errors["guarantJobInfo"]["supName"] = emptytxt;}
    if(!fields.guarantJobInfo["supphone"]){formIsValid = false; errors["guarantJobInfo"]["supphone"] = emptytxt;}
    if(!fields.guarantJobInfo["others"]){formIsValid = false; errors["guarantJobInfo"]["others"] = emptytxt;}
    if(!fields.guarantJobInfo["othersdetail"]){formIsValid = false; errors["guarantJobInfo"]["othersdetail"] = emptytxt;}
    //Detalles del prestamos 
    if(!fields.loanDetails["amount"]){formIsValid = false; errors.loanDetails["amount"] = emptytxt;}
    if(!fields.loanDetails["months"]){formIsValid = false; errors["loanDetails"]["months"] = emptytxt;}
    //if(!fields.loanDetails["loanPurpose"]){formIsValid = false; errors["loanDetails"]["loanPurpose"] = emptytxt;}

    //Detalles del vehiculo
    if(!fields.carDetails["brand"]){formIsValid = false; errors["carDetails"]["brand"] = emptytxt;}
    if(!fields.carDetails["model"]){formIsValid = false; errors["carDetails"]["model"] = emptytxt;}
    if(!fields.carDetails["year"]){formIsValid = false; errors["carDetails"]["year"] = emptytxt;}
    if(!fields.carDetails["valueAmount"]){formIsValid = false; errors["carDetails"]["valueAmount"] = emptytxt;}

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange = (field,event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;        
    this.setState({fields});
  };
  handleSonChange = (parent,field,event) => {
    let fields = this.state.fields;
    const target = event.target;
    if(target.type === 'checkbox'){
      fields[parent][field] = target.type === 'checkbox' ? target.checked : target.value;
    }else{
      fields[parent][field] = target.value; 
    }       
    this.setState({fields});
  };

  handleSubmit = event => {
    event.preventDefault();
    if(this.handleValidation()){
      this.setState({ disable: true });
      let fields = this.state.fields;
      // if(!this.state.disable){
        axios.post(this.url+'api/pv', {fields})
        .then(res => {
          const result = res.data;
          if(result == "SEND"){
            let MySwal = withReactContent(Swal)
            MySwal.fire({
              type: 'success',
              width: '70%',
              // title: <p>Su solicitud ha sido enviada.</p>,
              html: SuccessMessage
              // text: 'Gracias por haber completo el primer paso del proceso de solicitud le estaremos contactando en las próximas 24 horas para continuar con el proceso:'
            }).then(() => {
              this.setState({ redirect: true });
            })
          }else{
            let MySwalErr = withReactContent(Swal)
            MySwalErr.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Ha ocurrido un error!'
              // text: 'Gracias por haber completo el primer paso del proceso de solicitud le estaremos contactando en las próximas 24 horas para continuar con el proceso:'
            }).then(() => {
              this.setState({ redirect: true });
            })
          }
        });
      // }
    }else{
      alert("El formulario no esta completado de la manera correcta, revise los campos.")
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/home'/>;
    }
    return (
      <div>
        <div>
					<NavBar all={false} />
        </div>
        <div className="container page-padding">
        <form name="contactform" className="contactform" onSubmit={this.handleSubmit.bind(this)}>
          <div className="container">
            <br />
            <h5 className="sub-tittle-bold">Detalles personales</h5>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="namep">Nombres</label>
                  <input type="text" className="form-control" id="namep" placeholder="..." 
                  onChange={this.handleChange.bind(this,"namep")} value={this.state.fields["namep"]} />
                  <span className="error">{this.state.errors["namep"]}</span>
                </div>
                <fieldset className="form-group moredown">
                  <label for="namep">Sexo</label>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="Hombre" 
                        checked={this.state.fields.genderp === 'Hombre'} onChange={this.handleChange.bind(this,"genderp")}/>
                        <label className="form-check-label" for="gridRadios1">
                          Hombre
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="Mujer" 
                        checked={this.state.fields.genderp === 'Mujer'} onChange={this.handleChange.bind(this,"genderp")}/>
                        <label className="form-check-label" for="gridRadios2">
                          Mujer
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="form-group">
                  <label for="addressp">Direccion</label>
                  <input type="text" className="form-control" id="addressp" placeholder="..." 
                  onChange={this.handleChange.bind(this,"addressp")} value={this.state.fields["addressp"]} />
                  <span className="error">{this.state.errors["addressp"]}</span>
                </div>
                <div className="form-group">
                  <label for="sectorp">Sector</label>
                  <input type="text" className="form-control" id="sectorp" placeholder="..." 
                  onChange={this.handleChange.bind(this,"sectorp")} value={this.state.fields["sectorp"]} />
                  <span className="error">{this.state.errors["sectorp"]}</span>
                </div>
                <div className="form-group">
                  <label for="documentp">Cedula/Pasaporte</label>
                  <input type="text" className="form-control" id="documentp" placeholder="..." 
                  onChange={this.handleChange.bind(this,"documentp")} value={this.state.fields["documentp"]} />
                  <span className="error">{this.state.errors["documentp"]}</span>
                </div>
                <div className="form-group">
                  <label for="phonep">No. de telefono residencial</label>
                  <input type="text" className="form-control" id="phonep" placeholder="..." 
                  onChange={this.handleChange.bind(this,"phonep")} value={this.state.fields["phonep"]} />
                  <span className="error">{this.state.errors["phonep"]}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="lastnamep">Apellidos</label>
                  <input type="text" className="form-control" id="lastnamep" placeholder="..." 
                  onChange={this.handleChange.bind(this,"lastnamep")} value={this.state.fields["lastnamep"]} />
                  <span className="error">{this.state.errors["lastnamep"]}</span>
                </div>
                <div className="form-group">
                  <label for="birthdatep">Fecha de Nacimiento</label>
                  <input type="text" className="form-control" id="birthdatep" placeholder="..." 
                  onChange={this.handleChange.bind(this,"birthdatep")} value={this.state.fields["birthdatep"]} />
                  <span className="error">{this.state.errors["birthdatep"]}</span>
                </div>
                <div className="form-group">
                  <label for="cityp">Provincia</label>
                  <input type="text" className="form-control" id="cityp" placeholder="..." 
                  onChange={this.handleChange.bind(this,"cityp")} value={this.state.fields["cityp"]} />
                  <span className="error">{this.state.errors["cityp"]}</span>
                </div>
                <div className="form-group">
                  <label for="emailp">Correo Electronico</label>
                  <input type="text" className="form-control" id="emailp" placeholder="..." 
                  onChange={this.handleChange.bind(this,"emailp")} value={this.state.fields["emailp"]} />
                  <span className="error">{this.state.errors["emailp"]}</span>
                </div>
                <div className="form-group">
                  <label for="movilp">No. de telefono movil</label>
                  <input type="text" className="form-control" id="movilp" placeholder="..." 
                  onChange={this.handleChange.bind(this,"movilp")} value={this.state.fields["movilp"]} />
                  <span className="error">{this.state.errors["movilp"]}</span>
                </div>
              </div>
            </div>
            <div>
              <h5>Metodo de comunicacíon de su preferencia para contactarle:</h5>
              <div class="input-group">
                <div class="input-group-prepend">
                  <label><input type="checkbox" aria-label="Checkbox for following text input" 
                  checked={this.state.fields.contactPref.phoneCall} onChange={this.handleSonChange.bind(this,"contactPref","phoneCall")}/> Llamada Telefonica </label> 
                  </div>
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <label><input type="checkbox" aria-label="Checkbox for following text input"
                  checked={this.state.fields.contactPref.email} onChange={this.handleSonChange.bind(this,"contactPref","email")} /> Correo</label> 
                  </div>
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <label><input type="checkbox" aria-label="Checkbox for following text input"
                  checked={this.state.fields.contactPref.whatsApp} onChange={this.handleSonChange.bind(this,"contactPref","whatsApp")} /> WhatsApp</label> 
                  </div>
              </div>
            </div>
            <hr />
            <br />
            <h5 className="sub-tittle-bold">Información Laboral</h5>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="jobname">Nombre de la Empresa</label>
                  <input type="text" className="form-control" id="jobname" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"jobInfo","jobname")} value={this.state.fields["jobInfo"]["jobname"]} />
                  <span className="error">{this.state.errors.jobInfo["jobname"]}</span>
                </div>
                <div className="form-group">
                  <label for="rol">Cargo</label>
                  <input type="text" className="form-control" id="rol" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"jobInfo","rol")} value={this.state.fields["jobInfo"]["rol"]} />
                  <span className="error">{this.state.errors.jobInfo["rol"]}</span>
                </div>
                <div className="form-group">
                  <label for="department">Departamento/seccion</label>
                  <input type="text" className="form-control" id="department" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"jobInfo","department")} value={this.state.fields["jobInfo"]["department"]} />
                  <span className="error">{this.state.errors.jobInfo["department"]}</span>
                </div>
                <div className="form-group">
                  <label for="salary">Salario</label>
                  <input type="text" className="form-control" id="salary" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"jobInfo","salary")} value={this.state.fields["jobInfo"]["salary"]} />
                  <span className="error">{this.state.errors.jobInfo["salary"]}</span>
                </div>
                <div className="form-group">
                  <label for="othersdetail">Especifique procedencia de otros ingresos</label>
                  <textarea class="form-control" id="othersdetail" rows="3" 
                  onChange={this.handleSonChange.bind(this,"jobInfo","othersdetail")} value={this.state.fields["jobInfo"]["othersdetail"]}>
                  </textarea>
                  <span className="error">{this.state.errors.jobInfo["othersdetail"]}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="jobaddress">Direccion</label>
                  <input type="text" className="form-control" id="jobaddress" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"jobInfo","jobaddress")} value={this.state.fields["jobInfo"]["jobaddress"]} />
                  <span className="error">{this.state.errors.jobInfo["jobaddress"]}</span>
                </div>
                <div className="form-group">
                  <label for="jobmail">Correo de la empresa</label>
                  <input type="text" className="form-control" id="jobmail" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"jobInfo","jobmail")} value={this.state.fields["jobInfo"]["jobmail"]} />
                  <span className="error">{this.state.errors.jobInfo["jobmail"]}</span>
                </div>
                <div className="form-group">
                  <label for="supName">Supervisor Inmediato</label>
                  <input type="text" className="form-control" id="supName" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"jobInfo","supName")} value={this.state.fields["jobInfo"]["supName"]} />
                  <span className="error">{this.state.errors.jobInfo["supName"]}</span>
                </div>
                <div className="form-group">
                  <label for="supphone">No. de contacto del supervisor</label>
                  <input type="text" className="form-control" id="supphone" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"jobInfo","supphone")} value={this.state.fields["jobInfo"]["supphone"]} />
                  <span className="error">{this.state.errors.jobInfo["supphone"]}</span>
                </div>
                <div className="form-group">
                  <label for="others">Otros Ingresos</label>
                  <input type="text" className="form-control" id="others" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"jobInfo","others")} value={this.state.fields["jobInfo"]["others"]} />
                  <span className="error">{this.state.errors.jobInfo["others"]}</span>
                </div>
              </div>
            </div>
            <br />
            <h5 className="sub-tittle-bold">Referencias</h5>
            <br />
            <label className="sub-tittle-bold">Referencia personal 1</label> 
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="namep">Nombres</label>
                  <input type="text" className="form-control" id="namep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef1","namep")} value={this.state.fields["personalRef1"]["namep"]} />
                  <span className="error">{this.state.errors.personalRef1["namep"]}</span>
                </div>
                <fieldset className="form-group moredown">
                  <label for="namep">Sexo</label>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="gridRadios1" id="gridRadios3" value="Hombre" 
                        checked={this.state.fields.personalRef1.genderp === 'Hombre'} onChange={this.handleSonChange.bind(this,"personalRef1","genderp")}/>
                        <label className="form-check-label" for="gridRadios3">
                          Hombre
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="gridRadios1" id="gridRadios4" value="Mujer" 
                        checked={this.state.fields.personalRef1.genderp === 'Mujer'} onChange={this.handleSonChange.bind(this,"personalRef1","genderp")}/>
                        <label className="form-check-label" for="gridRadios4">
                          Mujer
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="form-group">
                  <label for="sectorp">Sector</label>
                  <input type="text" className="form-control" id="sectorp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef1","sectorp")} value={this.state.fields["personalRef1"]["sectorp"]} />
                  <span className="error">{this.state.errors.personalRef1["sectorp"]}</span>
                </div>
                <div className="form-group">
                  <label for="emailp">Correo Electronico</label>
                  <input type="text" className="form-control" id="emailp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef1","emailp")} value={this.state.fields["personalRef1"]["emailp"]} />
                  <span className="error">{this.state.errors.personalRef1["emailp"]}</span>
                </div>
                <div className="form-group">
                  <label for="phonep">No. de teléfono residencial</label>
                  <input type="text" className="form-control" id="phonep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef1","phonep")} value={this.state.fields["personalRef1"]["phonep"]} />
                  <span className="error">{this.state.errors.personalRef1["phonep"]}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="lastnamep">Apellidos</label>
                  <input type="text" className="form-control" id="lastnamep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef1","lastnamep")} value={this.state.fields["personalRef1"]["lastnamep"]} />
                  <span className="error">{this.state.errors.personalRef1["lastnamep"]}</span>
                </div>
                <div className="form-group">
                  <label for="addressp">Direccion</label>
                  <input type="text" className="form-control" id="addressp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef1","addressp")} value={this.state.fields["personalRef1"]["addressp"]} />
                  <span className="error">{this.state.errors.personalRef1["addressp"]}</span>
                </div>
                <div className="form-group">
                  <label for="cityp">Provincia</label>
                  <input type="text" className="form-control" id="cityp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef1","cityp")} value={this.state.fields["personalRef1"]["cityp"]} />
                  <span className="error">{this.state.errors.personalRef1["cityp"]}</span>
                </div>
                <div className="form-group">
                  <label for="movilp">No. de teléfono móvil</label>
                  <input type="text" className="form-control" id="movilp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef1","movilp")} value={this.state.fields["personalRef1"]["movilp"]} />
                  <span className="error">{this.state.errors.personalRef1["movilp"]}</span>
                </div>
                <div className="form-group">
                  <label for="relation">Tipo de relacion</label>
                  <input type="text" className="form-control" id="relation" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef1","relation")} value={this.state.fields["personalRef1"]["relation"]} />
                  <span className="error">{this.state.errors.personalRef1["relation"]}</span>
                </div>
              </div>
            </div>
            <br />
            <label className="sub-tittle-bold">Referencia personal 2</label> 
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="namep">Nombres</label>
                  <input type="text" className="form-control" id="namep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef2","namep")} value={this.state.fields["personalRef2"]["namep"]} />
                  <span className="error">{this.state.errors.personalRef2["namep"]}</span>
                </div>
                <fieldset className="form-group moredown">
                  <label for="namep">Sexo</label>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="gridRadios2" id="gridRadios5" value="Hombre" 
                        checked={this.state.fields.personalRef2.genderp === 'Hombre'} onChange={this.handleSonChange.bind(this,"personalRef2","genderp")}/>
                        <label className="form-check-label" for="gridRadios5">
                          Hombre
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="gridRadios2" id="gridRadios6" value="Mujer" 
                        checked={this.state.fields.personalRef2.genderp === 'Mujer'} onChange={this.handleSonChange.bind(this,"personalRef2","genderp")}/>
                        <label className="form-check-label" for="gridRadios6">
                          Mujer
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="form-group">
                  <label for="sectorp">Sector</label>
                  <input type="text" className="form-control" id="sectorp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef2","sectorp")} value={this.state.fields["personalRef2"]["sectorp"]} />
                  <span className="error">{this.state.errors.personalRef2["sectorp"]}</span>
                </div>
                <div className="form-group">
                  <label for="emailp">Correo Electronico</label>
                  <input type="text" className="form-control" id="emailp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef2","emailp")} value={this.state.fields["personalRef2"]["emailp"]} />
                  <span className="error">{this.state.errors.personalRef2["emailp"]}</span>
                </div>
                <div className="form-group">
                  <label for="phonep">No. de teléfono residencial</label>
                  <input type="text" className="form-control" id="phonep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef2","phonep")} value={this.state.fields["personalRef2"]["phonep"]} />
                  <span className="error">{this.state.errors.personalRef2["phonep"]}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="lastnamep">Apellidos</label>
                  <input type="text" className="form-control" id="lastnamep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef2","lastnamep")} value={this.state.fields["personalRef2"]["lastnamep"]} />
                  <span className="error">{this.state.errors.personalRef2["lastnamep"]}</span>
                </div>
                <div className="form-group">
                  <label for="addressp">Direccion</label>
                  <input type="text" className="form-control" id="addressp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef2","addressp")} value={this.state.fields["personalRef2"]["addressp"]} />
                  <span className="error">{this.state.errors.personalRef2["addressp"]}</span>
                </div>
                <div className="form-group">
                  <label for="cityp">Provincia</label>
                  <input type="text" className="form-control" id="cityp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef2","cityp")} value={this.state.fields["personalRef2"]["cityp"]} />
                  <span className="error">{this.state.errors.personalRef2["cityp"]}</span>
                </div>
                <div className="form-group">
                  <label for="movilp">No. de teléfono móvil</label>
                  <input type="text" className="form-control" id="movilp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef2","movilp")} value={this.state.fields["personalRef2"]["movilp"]} />
                  <span className="error">{this.state.errors.personalRef2["movilp"]}</span>
                </div>
                <div className="form-group">
                  <label for="relation">Tipo de relacion</label>
                  <input type="text" className="form-control" id="relation" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef2","relation")} value={this.state.fields["personalRef2"]["relation"]} />
                  <span className="error">{this.state.errors.personalRef2["relation"]}</span>
                </div>
              </div>
            </div>
            <br />
            <label className="sub-tittle-bold">Referencia personal 3</label> 
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="namep">Nombres</label>
                  <input type="text" className="form-control" id="namep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef3","namep")} value={this.state.fields["personalRef3"]["namep"]} />
                  <span className="error">{this.state.errors.personalRef3["namep"]}</span>
                </div>
                <fieldset className="form-group moredown">
                  <label for="namep">Sexo</label>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="gridRadios3" id="gridRadios7" value="Hombre" 
                        checked={this.state.fields.personalRef3.genderp === 'Hombre'} onChange={this.handleSonChange.bind(this,"personalRef3","genderp")}/>
                        <label className="form-check-label" for="gridRadios7">
                          Hombre
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="gridRadios3" id="gridRadios8" value="Mujer" 
                        checked={this.state.fields.personalRef3.genderp === 'Mujer'} onChange={this.handleSonChange.bind(this,"personalRef3","genderp")}/>
                        <label className="form-check-label" for="gridRadios8">
                          Mujer
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="form-group">
                  <label for="sectorp">Sector</label>
                  <input type="text" className="form-control" id="sectorp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef3","sectorp")} value={this.state.fields["personalRef3"]["sectorp"]} />
                  <span className="error">{this.state.errors.personalRef3["sectorp"]}</span>
                </div>
                <div className="form-group">
                  <label for="emailp">Correo Electronico</label>
                  <input type="text" className="form-control" id="emailp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef3","emailp")} value={this.state.fields["personalRef3"]["emailp"]} />
                  <span className="error">{this.state.errors.personalRef3["emailp"]}</span>
                </div>
                <div className="form-group">
                  <label for="phonep">No. de teléfono residencial</label>
                  <input type="text" className="form-control" id="phonep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef3","phonep")} value={this.state.fields["personalRef3"]["phonep"]} />
                  <span className="error">{this.state.errors.personalRef3["phonep"]}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="lastnamep">Apellidos</label>
                  <input type="text" className="form-control" id="lastnamep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef3","lastnamep")} value={this.state.fields["personalRef3"]["lastnamep"]} />
                  <span className="error">{this.state.errors.personalRef3["lastnamep"]}</span>
                </div>
                <div className="form-group">
                  <label for="addressp">Direccion</label>
                  <input type="text" className="form-control" id="addressp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef3","addressp")} value={this.state.fields["personalRef3"]["addressp"]} />
                  <span className="error">{this.state.errors.personalRef3["addressp"]}</span>
                </div>
                <div className="form-group">
                  <label for="cityp">Provincia</label>
                  <input type="text" className="form-control" id="cityp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef3","cityp")} value={this.state.fields["personalRef3"]["cityp"]} />
                  <span className="error">{this.state.errors.personalRef3["cityp"]}</span>
                </div>
                <div className="form-group">
                  <label for="movilp">No. de teléfono móvil</label>
                  <input type="text" className="form-control" id="movilp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef3","movilp")} value={this.state.fields["personalRef3"]["movilp"]} />
                  <span className="error">{this.state.errors.personalRef3["movilp"]}</span>
                </div>
                <div className="form-group">
                  <label for="relation">Tipo de relacion</label>
                  <input type="text" className="form-control" id="relation" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"personalRef3","relation")} value={this.state.fields["personalRef3"]["relation"]} />
                  <span className="error">{this.state.errors.personalRef3["relation"]}</span>
                </div>
              </div>
            </div>
            <br />
            <label className="sub-tittle-bold">Referencia Bancaria 1</label> 
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="bank">Banco</label>
                  <input type="text" className="form-control" id="bank" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef","bank")} value={this.state.fields["bankRef"]["bank"]} />
                  <span className="error">{this.state.errors.bankRef["bank"]}</span>
                </div>
                <div className="form-group">
                  <label for="office">Oficina/Sucursal</label>
                  <input type="text" className="form-control" id="office" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef","office")} value={this.state.fields["bankRef"]["office"]} />
                  <span className="error">{this.state.errors.bankRef["office"]}</span>
                </div>
                <div className="form-group">
                  <label for="phone">Teléfono</label>
                  <input type="text" className="form-control" id="phone" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef","phone")} value={this.state.fields["bankRef"]["phone"]} />
                  <span className="error">{this.state.errors.bankRef["phone"]}</span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="form-group">
                  <label for="bankAccount">No. De Cuenta</label>
                  <input type="text" className="form-control" id="bankAccount" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef","bankAccount")} value={this.state.fields["bankRef"]["bankAccount"]} />
                  <span className="error">{this.state.errors.bankRef["bankAccount"]}</span>
                </div>
                <div className="form-group">
                  <label for="bankPerson">Nombre del oficial de cuenta (Opcional)</label>
                  <input type="text" className="form-control" id="bankPerson" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef","bankPerson")} value={this.state.fields["bankRef"]["bankPerson"]} />
                  <span className="error">{this.state.errors.bankRef["bankPerson"]}</span>
                </div>
                <div className="form-group">
                  <label for="ext">Extensión</label>
                  <input type="text" className="form-control" id="ext" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef","ext")} value={this.state.fields["bankRef"]["ext"]} />
                  <span className="error">{this.state.errors.bankRef["ext"]}</span>
                </div>
              </div>
            </div>
            <br />
            <label className="sub-tittle-bold">Referencia Bancaria 2 (Opcional)</label> 
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="bank">Banco</label>
                  <input type="text" className="form-control" id="bank" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef2","bank")} value={this.state.fields["bankRef2"]["bank"]} />
                </div>
                <div className="form-group">
                  <label for="office">Oficina/Sucursal</label>
                  <input type="text" className="form-control" id="office" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef2","office")} value={this.state.fields["bankRef2"]["office"]} />
                </div>
                <div className="form-group">
                  <label for="phone">Teléfono</label>
                  <input type="text" className="form-control" id="phone" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef2","phone")} value={this.state.fields["bankRef2"]["phone"]} />
                </div>
              </div>
              <div className="col-md-6">
              <div className="form-group">
                  <label for="bankAccount">No. De Cuenta</label>
                  <input type="text" className="form-control" id="bankAccount" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef2","bankAccount")} value={this.state.fields["bankRef2"]["bankAccount"]} />
                </div>
                <div className="form-group">
                  <label for="bankPerson">Nombre del oficial de cuenta (Opcional)</label>
                  <input type="text" className="form-control" id="bankPerson" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef2","bankPerson")} value={this.state.fields["bankRef2"]["bankPerson"]} />
                </div>
                <div className="form-group">
                  <label for="ext">Extensión</label>
                  <input type="text" className="form-control" id="ext" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"bankRef2","ext")} value={this.state.fields["bankRef2"]["ext"]} />
                </div>
              </div>
            </div>
            <br />
            <div style={{textAlign: 'center'}}>
              <b>Importante: El garante ir a firmar el contrato la misma fecha que el deudor.</b>
            </div>
            <br />
            <label className="sub-tittle-bold">Datos del garante</label> 
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="namep">Nombres</label>
                  <input type="text" className="form-control" id="namep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantInfo","namep")} value={this.state.fields["guarantInfo"]["namep"]} />
                  <span className="error">{this.state.errors.guarantInfo["namep"]}</span>
                </div>
                <fieldset className="form-group moredown">
                  <label for="namep">Sexo</label>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="gridRadios11" id="gridRadios11" value="Hombre" 
                        checked={this.state.fields.guarantInfo.genderp === 'Hombre'} onChange={this.handleSonChange.bind(this,"guarantInfo","genderp")}/>
                        <label className="form-check-label" for="gridRadios11">
                          Hombre
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="gridRadios11" id="gridRadios12" value="Mujer" 
                        checked={this.state.fields.guarantInfo.genderp === 'Mujer'} onChange={this.handleSonChange.bind(this,"guarantInfo","genderp")}/>
                        <label className="form-check-label" for="gridRadios12">
                          Mujer
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="form-group">
                  <label for="addressp">Direccion</label>
                  <input type="text" className="form-control" id="addressp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantInfo","addressp")} value={this.state.fields["guarantInfo"]["addressp"]} />
                  <span className="error">{this.state.errors.guarantInfo["addressp"]}</span>
                </div>
                <div className="form-group">
                  <label for="sectorp">Sector</label>
                  <input type="text" className="form-control" id="sectorp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantInfo","sectorp")} value={this.state.fields["guarantInfo"]["sectorp"]} />
                  <span className="error">{this.state.errors.guarantInfo["sectorp"]}</span>
                </div>
                <div className="form-group">
                  <label for="documentp">Cedula/Pasaporte</label>
                  <input type="text" className="form-control" id="documentp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantInfo","documentp")} value={this.state.fields["guarantInfo"]["documentp"]} />
                  <span className="error">{this.state.errors.guarantInfo["documentp"]}</span>
                </div>
                <div className="form-group">
                  <label for="phonep">No. de telefono residencial</label>
                  <input type="text" className="form-control" id="phonep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantInfo","phonep")} value={this.state.fields["guarantInfo"]["phonep"]} />
                  <span className="error">{this.state.errors.guarantInfo["phonep"]}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="lastnamep">Apellidos</label>
                  <input type="text" className="form-control" id="lastnamep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantInfo","lastnamep")} value={this.state.fields["guarantInfo"]["lastnamep"]} />
                  <span className="error">{this.state.errors.guarantInfo["lastnamep"]}</span>
                </div>
                <div className="form-group">
                  <label for="birthdatep">Fecha de Nacimiento</label>
                  <input type="text" className="form-control" id="birthdatep" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantInfo","birthdatep")} value={this.state.fields["guarantInfo"]["birthdatep"]} />
                  <span className="error">{this.state.errors.guarantInfo["birthdatep"]}</span>
                </div>
                <div className="form-group">
                  <label for="cityp">Provincia</label>
                  <input type="text" className="form-control" id="cityp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantInfo","cityp")} value={this.state.fields["guarantInfo"]["cityp"]} />
                  <span className="error">{this.state.errors.guarantInfo["cityp"]}</span>
                </div>
                <div className="form-group">
                  <label for="emailp">Correo Electronico</label>
                  <input type="text" className="form-control" id="emailp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantInfo","emailp")} value={this.state.fields["guarantInfo"]["emailp"]} />
                  <span className="error">{this.state.errors.guarantInfo["emailp"]}</span>
                </div>
                <div className="form-group">
                  <label for="movilp">No. de telefono movil</label>
                  <input type="text" className="form-control" id="movilp" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantInfo","movilp")} value={this.state.fields["guarantInfo"]["movilp"]} />
                  <span className="error">{this.state.errors.guarantInfo["movilp"]}</span>
                </div>
              </div>
            </div>
            <br />
            <label className="sub-tittle-bold">Datos Laborales del garante</label> 
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="jobname">Nombre de la Empresa</label>
                  <input type="text" className="form-control" id="jobname" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantJobInfo","jobname")} value={this.state.fields["guarantJobInfo"]["jobname"]} />
                  <span className="error">{this.state.errors.guarantJobInfo["jobname"]}</span>
                </div>
                <div className="form-group">
                  <label for="rol">Cargo</label>
                  <input type="text" className="form-control" id="rol" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantJobInfo","rol")} value={this.state.fields["guarantJobInfo"]["rol"]} />
                  <span className="error">{this.state.errors.guarantJobInfo["rol"]}</span>
                </div>
                <div className="form-group">
                  <label for="department">Departamento/seccion</label>
                  <input type="text" className="form-control" id="department" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantJobInfo","department")} value={this.state.fields["guarantJobInfo"]["department"]} />
                  <span className="error">{this.state.errors.guarantJobInfo["department"]}</span>
                </div>
                <div className="form-group">
                  <label for="salary">Salario</label>
                  <input type="text" className="form-control" id="salary" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantJobInfo","salary")} value={this.state.fields["guarantJobInfo"]["salary"]} />
                  <span className="error">{this.state.errors.guarantJobInfo["salary"]}</span>
                </div>
                <div className="form-group">
                  <label for="othersdetail">Especifique procedencia de otros ingresos</label>
                  <textarea class="form-control" id="othersdetail" rows="3" 
                  onChange={this.handleSonChange.bind(this,"guarantJobInfo","othersdetail")} value={this.state.fields["guarantJobInfo"]["othersdetail"]}>
                  </textarea>
                  <span className="error">{this.state.errors.guarantJobInfo["othersdetail"]}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="jobaddress">Direccion</label>
                  <input type="text" className="form-control" id="jobaddress" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantJobInfo","jobaddress")} value={this.state.fields["guarantJobInfo"]["jobaddress"]} />
                  <span className="error">{this.state.errors.guarantJobInfo["jobaddress"]}</span>
                </div>
                <div className="form-group">
                  <label for="jobmail">Correo de la empresa</label>
                  <input type="text" className="form-control" id="jobmail" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantJobInfo","jobmail")} value={this.state.fields["guarantJobInfo"]["jobmail"]} />
                  <span className="error">{this.state.errors.guarantJobInfo["jobmail"]}</span>
                </div>
                <div className="form-group">
                  <label for="supName">Supervisor Inmediato</label>
                  <input type="text" className="form-control" id="supName" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantJobInfo","supName")} value={this.state.fields["guarantJobInfo"]["supName"]} />
                  <span className="error">{this.state.errors.guarantJobInfo["supName"]}</span>
                </div>
                <div className="form-group">
                  <label for="supphone">No. de contacto del supervisor</label>
                  <input type="text" className="form-control" id="supphone" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantJobInfo","supphone")} value={this.state.fields["guarantJobInfo"]["supphone"]} />
                  <span className="error">{this.state.errors.guarantJobInfo["supphone"]}</span>
                </div>
                <div className="form-group">
                  <label for="others">Otros Ingresos</label>
                  <input type="text" className="form-control" id="others" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"guarantJobInfo","others")} value={this.state.fields["guarantJobInfo"]["others"]} />
                  <span className="error">{this.state.errors.guarantJobInfo["others"]}</span>
                </div>
              </div>
            </div>
            <br />
            <label className="sub-tittle-bold">Detalles del préstamo</label> 
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="amount">Monto solicitado</label>
                  <input type="text" className="form-control" id="amount" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"loanDetails","amount")} value={this.state.fields["loanDetails"]["amount"]} />
                  <span className="error">{this.state.errors.loanDetails["amount"]}</span>
                </div>
                {/* <div className="form-group">
                  <label for="loanPurpose">Propósito del préstamo</label>
                  <textarea class="form-control" id="loanPurpose" rows="3" 
                  onChange={this.handleSonChange.bind(this,"loanDetails","loanPurpose")} value={this.state.fields["loanDetails"]["loanPurpose"]}>
                  </textarea>
                  <span className="error">{this.state.errors.loanDetails["loanPurpose"]}</span>
                </div> */}
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="months">Tiempo (en meses)</label>
                  <input type="text" className="form-control" id="months" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"loanDetails","months")} value={this.state.fields["loanDetails"]["months"]} />
                  <span className="error">{this.state.errors.loanDetails["months"]}</span>
                </div>
              </div>
            </div>
            <br />
            <label className="sub-tittle-bold">Detalles del vehiculo</label> 
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label for="brand">Marca</label>
                  <input type="text" className="form-control" id="brand" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"carDetails","brand")} value={this.state.fields["carDetails"]["brand"]} />
                  <span className="error">{this.state.errors.carDetails["brand"]}</span>
                </div>
                <div className="form-group">
                  <label for="model">Modelo</label>
                  <input type="text" className="form-control" id="model" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"carDetails","model")} value={this.state.fields["carDetails"]["model"]} />
                  <span className="error">{this.state.errors.carDetails["model"]}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="year">Año</label>
                  <input type="text" className="form-control" id="year" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"carDetails","year")} value={this.state.fields["carDetails"]["year"]} />
                  <span className="error">{this.state.errors.carDetails["year"]}</span>
                </div>
                <div className="form-group">
                  <label for="valueAmount">Valor de Compra</label>
                  <input type="text" className="form-control" id="valueAmount" placeholder="..." 
                  onChange={this.handleSonChange.bind(this,"carDetails","valueAmount")} value={this.state.fields["carDetails"]["valueAmount"]} />
                  <span className="error">{this.state.errors.carDetails["valueAmount"]}</span>
                </div>
              </div>
            </div>
            <b>Nota:</b>
            <p>“Declaro que toda la información suministrada es verdadera y autorizo a Inversiones Norvi SRL a que 
            realice las investigaciones que considere necesarias para la evaluación de mi solicitud de crédito.”</p>
          </div>
          <div style={{marginBottom: '60px'}}>
            <button type="submit" className="btn btn-primary from-button" disabled={this.state.disable}>Enviar solicitud</button>
          </div>
        </form>
        </div>
      </div>
    );
  };
};


export default Vehicle_Form;