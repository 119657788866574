import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { Link as Link2, animateScroll as scroll } from "react-scroll";

export default props => {
  return (
    // Pass on our props
    <Menu {...props}>
          <Link2 className="nav-item new-nav-item"
              activeClass="nav-item-active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
          >
              Inicio
                </Link2>
          <Link2 className="nav-item new-nav-item"
              activeClass="nav-item-active"
              to="servicios"
              spy={true}
              smooth={true}
              offset={-55}
              duration={500}
          >
              Servicios
                </Link2>

          <Link2 className="nav-item new-nav-item"
              activeClass="nav-item-active"
              to="prestamos"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
          >
              Solicitudes
                </Link2>

          <Link2 className="nav-item new-nav-item"
              activeClass="nav-item-active"
              to="contactanos"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
          >
              Contactanos
                </Link2>
    </Menu>
  );
};