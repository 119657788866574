import React from 'react';
import ReactDOM from "react-dom";

class SuccessMessage extends React.Component{
    render(){
        return(
            <div className="container" style={{color: '#003057'}}>
                <h2 class="section-title">Gracias por haber completo el primer paso del proceso de solicitud le estaremos contactando en las 
        próximas 24 horas para continuar con el proceso:</h2>
    <div id="content">
        <ol>
            <li>Entrevista inicial vía telefónica con el representante de Inversiones Norvi. Durante la entrevista se le 
                informa de la cuota tentativa, tomando en cuenta que esta puede cambiar en base a los pasos siguientes.</li>
            <li>Enviar los siguientes documentos vía correo electrónico:
                <div>
                    <ol class="roman">
                        <li>Copia de la cedula de ambos lados; tanto del garante como del solicitando.</li>
                        <li>Estados de cuentas bancarios con los movimientos de los últimos tres meses.</li>
                        <li>Carta de trabajo firmada y sellada por la empresa donde laboral.</li>
                    </ol>
                </div>
            </li>
            <li>Los documentos son procesados; depende de los requerimientos llamadas extras serán requeridas.</li>
            <li>Se le contactara con su cuota final.</li>
            <li>El préstamo se aprueba o rechaza. En caso de ser aprobado, se coordinará una cita para firma del contrato</li>
            <li>Luego firmado el contrato los fondos se depositan de 24-48 horas laborables</li>
        </ol>
    </div>
    <b>IMPORTANTE: Por favor tomar en cuenta que todos los prestamos llevan un gasto de cierre de un 5% 
        del capital el cual será descontado al momento del desembolso.</b>
            </div>
        );
    };
};

const wrapper = document.createElement("div");
ReactDOM.render(<SuccessMessage />, wrapper);

export default wrapper.firstChild;