import React from 'react';
import paper from '../../../../assets/media/imgs/paper.svg';
import handout from '../../../../assets/media/imgs/handout.svg';
import language from '../../../../assets/media/imgs/language.svg';
import handshake from '../../../../assets/media/imgs/handshake.svg';

class ServiciosCard extends React.Component{
	constructor(props) {
    super(props);
	}
	
	render() {
		return(
			<div className="row">
				<div className="card servicios-card">
					<div className="col-md-12 col-SM-12">
							<img src={this.props.imgsrc} className="card-img-top servicios-img" alt="..."/>
							<h5 className="servicios-para">{this.props.servtext}</h5>
					</div>
				</div>
			</div>
		)
	}
}

class Servicios extends React.Component {
  render() {
    return(
      <div className="servicios section-stl">
      	<div className="text-center servicios-title">
					<h1 className="text-center section-title">Servicios que ofrecemos</h1>
					<hr className="section-hr-line" />
					<div className="card-deck servicios-body">
						<div className="container">
							<div className="row">
								<div className="col-md-3 col-sm-12">
									<ServiciosCard imgsrc={handshake} servtext="Prestamos personales y de vehiculos"/>
								</div>
								<div className="col-md-3 col-sm-12">
									<ServiciosCard imgsrc={language} servtext="Accesoria financiera y de inversiones"/>
								</div>
								<div className="col-md-3 col-sm-12">
									<ServiciosCard imgsrc={paper} servtext="Servicios de Factoring"/>
								</div>
								<div className="col-md-3 col-sm-12">
									<ServiciosCard imgsrc={handout} servtext="Financiamentos"/>
								</div>
							</div>
						</div>
					</div>
				</div>
      </div>
    );
  }
}

export default Servicios;
