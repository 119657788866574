import React from 'react';
import phone_call from '../../../..//assets/media/imgs/phone_call.svg';
import square_pin from '../../../..//assets/media/imgs/square-pin.svg';
import email from '../../../..//assets/media/imgs/email.svg';

class ContactCard extends React.Component{
	constructor(props) {
    super(props);
	}
	
	render() {
		return(
			<div className="card justify-content-center text-center contactUsBoxes">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <img src={this.props.imgsrc} className="card-img-top box-img" alt="ups!" />
            <p className="card-body box-title">{this.props.desc}</p>
            <p className="card-body box-body"><a className="contact-link" rel="noopener noreferrer" href={this.props.detail}>{this.props.detailval}</a></p>
          </div>
        </div>
      </div>
		)
	}
}

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"info@inversionesnorvi.com",
      phone:"8099608274"
    };
  }
  

  render() {
    return (
      <div> 
        <div className="contactenos section-stl section-norvi-gray">
        <h1 className="text-center section-title">Contactenos</h1>
        <hr className="section-hr-line"/>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <ContactCard imgsrc={phone_call} desc="TELEFONO" detail={"callto:" + this.state.phone} detailval="809-960-8274"/>
            </div>
            <div className="col-md-4 col-sm-12">
              <ContactCard imgsrc={square_pin} desc="DIRECCIÓN" detail={"https://goo.gl/maps/qUTB7Br6NsP2"} detailval="Max Herinque Ureña Esq. Miguel A. Garrido #101, Suite B-7, Los Prados, Santo Domingo"/>
            </div>
            <div className="col-md-4 col-sm-12">
              <ContactCard imgsrc={email} desc="CORREO ELÉCTRONICO" detail={"mailto:" + this.state.email} detailval="info@inversionesnorvi.com"/>
            </div>
          </div>
        </div>
        
          {/* <div className="container card-columns">
            <div className="card justify-content-center text-center contactUsBoxes">
              <img src={phone_call} className="card-img-top box-img" alt="telephone" />
              <p className="card-body box-title">TELEFONO</p>
              <p className="card-body box-body"><a className="contact-link" href={"callto:" + this.state.phone}>809-960-8274</a></p>
            </div>
            <div className="card justify-content-center text-center contactUsBoxes">
            <img src={square_pin} className="card-img-top box-img" alt="square-pin" />
              <p className="card-body box-title">DIRECCIÓN</p>
              <p className="card-body box-body"><a className="contact-link" target="_blank"rel="noopener noreferrer" href="https://goo.gl/maps/qUTB7Br6NsP2">Max Herinque Ureña Esq. Miguel A. Garrido #101, Suite B-7, Los Prados, Santo Domingo</a></p>
            </div>
            <div className="card justify-content-center text-center contactUsBoxes">
            <img src={email} className="card-img-top box-img" alt="email" />
              <p className="card-body box-title">CORREO ELÉCTRONICO</p>
              <p className="card-body box-body"><a className="contact-link" href={"mailto:" + this.state.email}>info@inversionesnorvi.com</a></p>
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}

export default ContactUs