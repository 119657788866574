import React from 'react';
import Illustration from '../../../../assets/media/imgs/Illustration.svg';
import { Link as Link2 } from "react-scroll";

class Home extends React.Component{
  render() {
    return(
      <div className="header-body section-stl" id="home_section">
        <div className="container header-body">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="card header">
                <div className="card-body">
                  <h1 className="card-title header-title">Préstamos a la mejor tasa del mercado</h1>
                  <hr className="header-line"/>
                  <p className="card-text header-para">
                    Inversiones Norvi es una empresa que se dedica a los servicios<br/>
                    de indole financieros. Orientando a la mejora de las finanzas<br/>
                    personales y corporativasde nuestros clientes en funcion de los<br/>
                    objetivos de estos.<br/>
                  </p>
                  <Link2
                  to="prestamos"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  >
                  <button type="button" className="btn btn-primary calculator-button header-btn">
                    <b>¡Solicita tu préstamo ahora!</b>
                  </button>
                  </Link2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <img src={Illustration} className="img-fluid header-img justify-content-right" alt="Imagen de encabezado"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
