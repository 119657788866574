import React from 'react';
import Logo from '../../../assets/media/imgs/Logo-2.svg';
import { Link } from "react-router-dom";
import { Link as Link2, animateScroll as scroll } from "react-scroll";
import SideBar from "../_layouts/burger";


class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    if (this.props.all) {
      return (
        <div id="App">
          <div className="logo-img logo-props">
            <Link2
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            >
              <img
                src={Logo}
                alt="logo de norvi"
                onClick={this.scrollToTop}
              />
            </Link2>
          </div>

          <nav className="navbar navbar-expand-lg new-nav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item new-nav-item2">
                <a style={{color: 'white'}}>.</a>
              </li>
              <li className="nav-item new-nav-item not-display-movil">
                <Link2
                  activeClass="nav-item-active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Inicio
                </Link2>
              </li>
              <li className="nav-item new-nav-item not-display-movil">
                <Link2
                  activeClass="nav-item-active"
                  to="servicios"
                  spy={true}
                  smooth={true}
                  offset={-55}
                  duration={500}
                >
                  Servicios
                </Link2>
              </li>

              {/* <li className="nav-item new-nav-item">
                <Link2
                  activeClass="nav-item-active"
                  to="calculadora"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  Calculadora
                </Link2>
              </li> */}

              <li className="nav-item new-nav-item not-display-movil">
                <Link2
                  activeClass="nav-item-active"
                  to="prestamos"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  Solicitudes
                </Link2>
              </li>

              <li className="nav-item new-nav-item not-display-movil">
                <Link2
                  activeClass="nav-item-active"
                  to="contactanos"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  Contactanos
                </Link2>
              </li>

            </ul>
          </nav>
        </div>

      );
    } else {
      return (
        <div>
          <div className="logo-img logo-props">
            <Link
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            >
              <img
                src={Logo}
                alt="logo de norvi"
                onClick={this.scrollToTop}
              />
            </Link>
          </div>
          <nav className="navbar navbar-expand-lg new-nav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item new-nav-item">
                <Link
                  activeClass="nav-item-active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Inicio
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      );
    }
  }
}

export default NavBar;