import React from 'react';
import { Link } from "react-router-dom";





class FormCards extends React.Component {

     
    render() {
        return (
             <div className="section-stl">
                <div className="container">
                <h1 className="text-center section-title">Solicite su prestamo</h1>
                    <hr className="section-hr-line"/>
                    <div className="row card-columns">
                        <div className="col-md-4 offset-md-1 margin-cards">
                            <div className="card shadow p-3 mb-5 bg-white rounded">
                                <div className="justify-content-center text-center card-body margin-cards">
                                    <h5 className="card-title loan-cards-title servicios-para"><b>Prestamo Personal</b></h5>
                                    <p className="card-text servicios-para">Adquiere eso que tanto deseas con nuestros prestamos personales.</p>
                                    <Link to="/Personal_form">
                                        <button type="button" className="btn btn-primary calculator-button"><b>Solicitar</b></button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 offset-md-2 margin-cards">
                            <div className="card shadow p-3 mb-5 bg-white rounded">
                                <div className="justify-content-center text-center card-body margin-cards">
                                    <h5 className="card-title loan-cards-title servicios-para"><b>Prestamo de Vehiculo</b></h5>
                                    <p className="card-text servicios-para">Si estás buscando comprar un vehículo tenemos un préstamo para ti.</p>
                                    <Link to="/Vehicle_Form">
                                        <button type="button" className="btn btn-primary calculator-button
                                        "><b>Solicitar</b></button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormCards;