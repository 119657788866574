import React from 'react';
import Twitter from '../../../assets/media/imgs/Twitter.svg';
import Facebook from '../../../assets/media/imgs/Facebook.svg';

class Footer extends React.Component {
  render() {
    return (
      <div>
        <ul className="footer">
          <li className="footer-left">
            <span>Inversiones Norvi Inc. © 2019. </span>
          </li>
          <li className="footer-item">
            <img src={Facebook} alt="..." className="logos-redes" />
          </li>
          <li className="footer-item">
            <img src={Twitter} alt="..." className="logos-redes" />
          </li>
          <li className="footer-item">
            <a className="text-muted" target="_blank"rel="noopener noreferrer" href="https://www.instagram.com/inversionesnorvi/?hl=es">@inversionesnorvi</a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Footer;