import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './app/css/styles.css';
import BodyContent from './app/components/_layouts/body';
import Footer from './app/components/_layouts/footer';
import PersonalForm from './app/components/site/loans_forms/PersonalForm/personal_Form';
import VehicleForm from './app/components/site/loans_forms/VehicleForm/vehicle_Form';
import LoanCalculator from './app/components/site/calculator/loan_calculatorN';
import { BrowserRouter as Router, Route } from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <div className="wrap">
            <Route exact strict path="/" component={BodyContent} />
            <Route exact strict path="/home" component={BodyContent} />
            <Route exact strict path="/Personal_form" component={PersonalForm} />
            <Route exact strict path="/Vehicle_form" component={VehicleForm} />
            <Route exact strict path="/Loan_Calculator" component={LoanCalculator} />
          </div>
        </Router>
        <div>
        <Footer />
        </div>
      </div>
    );
  }
}



// ========================================

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
