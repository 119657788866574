import React from 'react';
import NavBar from '../../_layouts/navBar';
import CurrencyFormat from 'react-currency-format';

// 
// Este componente calcula los prestamos
// 


class Loan_Calculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cuota: 0,
            monto: 0,
            plazo: 0,
            interes: 0,
            loanTable: [],
            loanSimpleTable: []
        };

        this.calcRowResult = this.calcRowResult.bind(this); 
        this.calcular = this.calcular.bind(this);
        this.fillArray = this.fillArray.bind(this);
        this.fillSimpleTable = this.fillSimpleTable.bind(this);
      }
       
    calcular = (event) => {
        if(event.key == 'Enter'){
            if(this.state.interes > 0 && this.state.monto && this.state.plazo){
                var i = (this.state.interes * 12) / 1200;
                let loan = this.state.monto / ((1 - Math.pow(i + 1, -this.state.plazo)) / i);
                this.setState({ cuota: loan });
                // console.log(loan);
                this.fillArray(this.state.monto,this.state.plazo,this.state.interes);
                this.fillSimpleTable(this.state.monto,this.state.plazo,this.state.interes);
            }
        }
    };

    calcRowResult(interest,bInicial,cuota){
     let result = {};
        result.interest = (interest/100) * bInicial;
        result.capitalAmount = cuota - result.interest;
        result.bInicial = bInicial - result.capitalAmount;
    
        return result;
    }
    
    fillArray(capitalIn, months, interest){
        this.state.loanTable = [];
        let capital = capitalIn;
        var i = (this.state.interes * 12) / 1200;
        let cuota = this.state.monto / ((1 - Math.pow(i + 1, -this.state.plazo)) / i); /*le puse 0 pero debe tener el valor de la cuota despues del calculo 
        de la formula de sacar la cuota*/
    
        for(let i =0; i<months; i++){
          let rowTLoan = {
            id: 0,
            fee: 0,
            capitalAmount: 0,
            balance: 0,
            cuota: 0 
          }
          let resultVals = this.calcRowResult(interest,capital,cuota);
          capital = resultVals.bInicial;
          rowTLoan.id = i+1;
          rowTLoan.fee = resultVals.interest.toFixed(2);
          rowTLoan.capitalAmount = resultVals.capitalAmount.toFixed(2);
          rowTLoan.balance = resultVals.bInicial.toFixed(2);
          rowTLoan.cuota = cuota.toFixed(2);
          this.state.loanTable.push(rowTLoan);
        }
    }
    
    fillSimpleTable(capitalIn, months, interest) {
        this.state.loanSimpleTable = [];
        var iinterest = (this.state.interes * 12) / 1200;

        for(let i=0; i<months;i++){
            let rowTLoan = {
                id: 0,
                fee: 0,
                capitalAmount: 0,
                balance: 0,
                cuota: 0 
              }
            if(i == months - 1){
                rowTLoan.id = i+1;
                rowTLoan.fee = iinterest * capitalIn;
                rowTLoan.capitalAmount = capitalIn;
                rowTLoan.balance = 0;
                rowTLoan.cuota = iinterest * capitalIn;
            }else{
                rowTLoan.id = i+1;
                rowTLoan.fee = iinterest * capitalIn;
                rowTLoan.capitalAmount = 0;
                rowTLoan.balance = capitalIn;
                rowTLoan.cuota = iinterest * capitalIn;
            }
            this.state.loanSimpleTable.push(rowTLoan);
        }

    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        return (
            <div>
                <div>
					<NavBar all={false} />
                </div>
                <div className="container page-padding withoutPadding">
                    <div className="Calculator-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <form name="calcform" onSubmit={this.calcular.bind(this)}>
                                        <h1 className="calculator-h1">Calculadora</h1>
                                        <hr className="cal-hr" />
                                        <br />
                                        <p className="calculator-text">Monto</p>
                                        <input className="form-control" name="monto" type="number" placeholder="Monto" 
                                        value={this.state.monto} onChange={e => this.change(e)}
                                        onKeyPress={this.calcular.bind(this)}/>
                                        <br />
                                        <p className="calculator-text">Plazo(meses)</p>
                                        <input className="form-control" name="plazo" type="number" placeholder="Plazo" 
                                        value={this.state.plazo} onChange={e => this.change(e)} 
                                        onKeyPress={this.calcular.bind(this)}/>                       
                                        <br />
                                        <p className="calculator-text">Interes Mensual(%)</p>
                                        <input className="form-control" name="interes"  type="number" placeholder="Interes" 
                                        value={this.state.interes} onChange={e => this.change(e)} 
                                        onKeyPress={this.calcular.bind(this)}/>
                                    </form>
                                </div>
                                <div className="col-md-4 offset-md-2 col-sm-12"> 
                                    <div>
                                        <br />
                                        <h3>Cuota mensual</h3>
                                        <hr className="cal-hr"/>
                                        <label>
                                            {this.state.cuota.toFixed(2)}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="Calculator-box"> */}
                        <div className="row">
                            <div className="col-md-12">
                                <br />
                                <Calculator_Table loanTable={this.state.loanTable} loanSimpleTable={this.state.loanSimpleTable}/>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

class Calculator_Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cuota: 0,
            monto: 0,
            plazo: 0,
            interes: 0,
            loanTable: [],
            activeTab: 'personal-form'
        };
      }

    setActiveTab(newActiveTab) {
        this.setState({
          activeTab: newActiveTab
        });
      }
    render() {
    return (
            <div>
                    <div className="table-box">
                            <ul className="nav nav-tabs nav-fill">
                                <li className="nav-item">
                                    <a onClick={()=> this.setActiveTab('personal-form')} 
                                    id="personal-form-tab" 
                                    className={this.state.activeTab === 'personal-form' ? "nav-link active" : "nav-link"}
                                    >Compuesta
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={()=> this.setActiveTab('vehicle-form')} 
                                    id="vehicle-form-tab" 
                                    className={this.state.activeTab === 'vehicle-form' ? "nav-link active" : "nav-link"} 
                                    >Simple</a>
                                </li>
                            </ul>

                    </div>
                <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            {
                            this.state.activeTab === 'vehicle-form' ?
                                <Tabla1 loanTable={this.props.loanSimpleTable}/>
                            :
                                <Tabla2 loanTable={this.props.loanTable}/>
                            }
                        </div>
                    </div>
            </div>
        );
    }
}



class Tabla1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cuota: 0,
            monto: 0,
            plazo: 0,
            interes: 0,
            loanTable: []
        };

        this.renderRows = this.renderRows.bind(this);
      }
    componentDidMount(){
        
    }

    renderRows(loanRow, index) {
        return (
          <tr key={index}>
            <td>{loanRow.id}</td>
            <td><CurrencyFormat value={loanRow.cuota} displayType={'text'} 
            thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={'$'}/></td>
            <td><CurrencyFormat value={loanRow.fee} displayType={'text'} 
            thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={'$'}/></td>
            <td><CurrencyFormat value={loanRow.capitalAmount} displayType={'text'} 
            thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={'$'}/></td>
            <td><CurrencyFormat value={loanRow.balance} displayType={'text'} 
            thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={'$'}/></td>
          </tr>
        )
      }
      render(){
        return(
            <div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th>Id</th>
                        <th>Cuota</th>
                        <th>Interes</th>
                        <th>Capital</th>
                        <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.loanTable.map(this.renderRows)}
                    </tbody>
                </table>
            </div>
        )
    }
}

class Tabla2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cuota: 0,
            monto: 0,
            plazo: 0,
            interes: 0,
            loanTable: []
        };

        this.renderRows = this.renderRows.bind(this);
      }
    componentDidMount(){
        
    }

    renderRows(loanRow, index) {
        return (
            <tr key={index}>
                <td>{loanRow.id}</td>
                <td><CurrencyFormat value={loanRow.cuota} displayType={'text'} 
                thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={'$'}/></td>
                <td><CurrencyFormat value={loanRow.fee} displayType={'text'} 
                thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={'$'}/></td>
                <td><CurrencyFormat value={loanRow.capitalAmount} displayType={'text'} 
                thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={'$'}/></td>
                <td><CurrencyFormat value={loanRow.balance} displayType={'text'} 
                thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={'$'}/></td>
            </tr>
        )
      }

    render(){
        return(
            <div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th>Id</th>
                        <th>Cuota</th>
                        <th>Interes</th>
                        <th>Capital</th>
                        <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.loanTable.map(this.renderRows)}
                    </tbody>
                </table>
            </div>
        )
    }
}



const Calculator_Rendering = function (props) {
    return (
        <div>
            <Loan_Calculator />
        </div>
    )
}






export default Calculator_Rendering;
