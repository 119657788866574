import React from 'react';
import { Link } from "react-router-dom";

const calculatorSection = (props) => {
    return (
        <div className="calculator-section2 section-norvi-gray">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-8">
                        <div id="centralizacion">
                            <h1 className="text-center section-title">
                                Simulador de prestamos
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2 offset-md-5">
                        <Link to="/Loan_Calculator">
                            <button type="button" class="btn btn-primary btnCalc">
                                <b>Calcular</b>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>                        
        </div>
    )
}

export default calculatorSection;